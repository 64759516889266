<script setup lang="ts">
import { useScheduleColumns } from './composables/useScheduleColumns'
import useColumnLoading from './composables/useColumnLoading'

const route = useRoute('division-companyId-audit')
const { currentDivision } = useDivisions()

const props = defineProps<{
  searchQuery: string
}>()

const { data: accuraitSchedules, isFetching } = useAPIFetchAccuraitSchedule(
  currentDivision.value.id,
)

const filteredAccuraitSchedules = computed(() => {
  if (!props.searchQuery) return accuraitSchedules.value?.data || []

  return (
    accuraitSchedules.value?.data?.filter((schedule) =>
      schedule.tenantName
        ?.toString()
        .toLowerCase()
        .includes(props.searchQuery?.toLowerCase()),
    ) || []
  )
})

const colsSkeleton = useColumnLoading()
const columns = useScheduleColumns(
  computed(() => accuraitSchedules.value?.columns ?? colsSkeleton),
)
</script>

<template>
  <div class="flex h-full flex-col">
    <div class="flex min-h-12 w-full items-center gap-6">
      <div class="flex items-center gap-2">
        <NuxtImg src="/assets/accurait-logo-primary.svg" class="w-10" />
        <span class="text-xl font-bold">Accurait</span>
      </div>
      <div class="flex items-center gap-3">
        <NuxtLink
          :to="`/division/${route.params.companyId}/workflow`"
          class="hover:text-primary text-sm"
          >Upload
        </NuxtLink>
      </div>
    </div>

    <div
      class="my-4 flex size-full overflow-x-auto rounded-lg p-0"
      :class="
        (filteredAccuraitSchedules && filteredAccuraitSchedules.length > 0) ||
        isFetching
          ? 'border border-gray-800'
          : ''
      "
    >
      <DataTable
        :columns="columns"
        :data="filteredAccuraitSchedules"
        :loading="isFetching"
        class="h-[460px] w-full min-w-max rounded-lg"
      >
        <template #empty>
          <section
            class="relative flex h-[460px] w-full flex-col items-center justify-center gap-6 rounded-lg border-2 border-dashed border-gray-800 bg-gray-200 py-10 transition-all dark:border-gray-700 dark:bg-gray-800"
          >
            <div
              class="flex flex-col items-center justify-center gap-3 text-center text-xl font-bold text-white"
            >
              <span> To generate LR Tenancy Schedule Upload </span>
              <span> your Leases at </span>
            </div>

            <NuxtLink
              :to="`/division/${route.params.companyId}/workflow`"
              class="text-primary cursor-pointer underline"
            >
              Upload and Review Page
            </NuxtLink>
          </section>
        </template>
      </DataTable>
    </div>
  </div>
</template>
<style scoped>
:deep(tbody > tr) {
  td:first-child {
    @apply border-r;
  }
  td {
    @apply border-y !border-gray-800;
  }
}
</style>
